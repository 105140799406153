export enum ErrorCode {
  AUTH_PASSWORD_OR_EMAIL_INCORRECT = 'ERROR.AUTH.PASSWORD_OR_EMAIL_INCORRECT',
  AUTH_USER_ALREADY_EXISTS = 'ERROR.AUTH.USER_ALREADY_EXISTS',
  CRAWL_INVALID_URL = 'ERROR.CRAWL.INVALID_URL',
  CRAWL_NO_CONTENT_EXTRACTED = 'ERROR.CRAWL.CONTENT_MISSING',
  CREDITS_INSUFFICIENT = 'ERROR.CREDITS_INSUFFICIENT',
  DB_FIND = 'ERROR.DB.FIND',
  DB_MISSING_SEED = 'ERROR.DB.MISSING_SEED',
  DB_SAVE = 'ERROR.DB.SAVE',
  DB_UPDATE = 'ERROR.DB.UPDATE',
  FILE_UPLOAD_GENERIC = 'ERROR.FILE_UPLOAD.GENERIC',
  FILE_UPLOAD_MAX_SIZE_EXCEEDED = 'ERROR.FILE_UPLOAD.MAX_SIZE_EXCEEDED',
  GENERIC = 'ERROR.GENERIC',
  SCAN_CANCELED = 'ERROR.SCAN.CANCELED',
  INVITE_ALREADY_ASSIGNED_TO_ORG = 'ERROR.INVITE.ALREADY_ASSIGNED_TO_ORG',
  INVITE_ALREADY_INVITED = 'ERROR.INVITE.ALREADY_INVITED',
  INVITE_NOT_FOUND = 'ERROR.INVITE.NOT_FOUND',
  LINKEDIN_NO_LEADS_FOUND = 'ERROR.LINKEDIN,NO_LEADS_FOUND',
  LINKEDIN_NO_PROFILE_FOUND = 'ERROR.LINKEDIN,NO_PROFILE_FOUND',
  OPENAI_GENERIC = 'ERROR.OPENAI.GENERIC',
  PROFILE_CREATE = 'ERROR.PROFILE.CREATE',
  RECAPTCHA = 'ERROR.RECAPTCHA',
  STRIPE_CANCEL_SUBSCRIPTION = 'ERROR.STRIPE.CANCEL_SUBSCRIPTION',
  STRIPE_CANT_VERIFY_SIGNATURE = 'ERROR.STRIPE.CANT_VERIFY_SIGNATURE',
  STRIPE_GENERIC = 'ERROR.STRIPE.GENERIC',
  STRIPE_TAX_ERROR = 'ERROR.STRIPE.TAX_ERROR',
  STRIPE_USER_UPDATE = 'ERROR.STRIPE.USER_UPDATE',
  DELETE_SUPERADMIN = 'ERROR.SUPERADMIN.DELETE',
  USER_NOT_SUPERADMIN = 'ERROR.NOT.SUPERADMIN',
  EDIT_USER = 'ERROR.USER.EDIT',
}
